import { UseQueryOptions } from '@tanstack/react-query';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GenericFailure } from '@playq/services-shared';
import { AppBlueprintEntityResponse } from '@playq/octopus2-analytics';

import { useEitherQuery } from '/api';

import { blueprintsService, blueprintsServiceName } from './constants';
import { IUseBlueprintRetrieveParams, IGetBlueprintIDForQueryParams } from './types';
import { getBlueprintIDForQuery } from './helpers';

export const useBlueprintRetrieve = (
  { appID, blueprintID, version }: IUseBlueprintRetrieveParams,
  options?: UseQueryOptions<AppBlueprintEntityResponse | undefined, Error | GenericFailure>
) => {
  const isQueryEnabled: boolean = appID !== undefined && blueprintID !== undefined;

  return useEitherQuery(
    [blueprintsServiceName, 'retrieve', appID, blueprintID, version],
    blueprintsService.retrieve.bind(
      blueprintsService,
      getBlueprintIDForQuery({ appID, blueprintID, isQueryEnabled } as IGetBlueprintIDForQueryParams),
      version
    ),
    {
      enabled: Boolean(isQueryEnabled),
      ...options,
    }
  );
};
