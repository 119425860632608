import { useMemo } from 'react';
import { Updater, UseQueryOptions } from '@tanstack/react-query';

import {
  BlueprintEntityID,
  QueryBlueprintEntitiesResponse,
  BlueprintEntityPromotions,
} from '@playq/octopus2-analytics';
import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';

import { IEntityPromotion, services2, useEitherQuery } from '/api';

import { IUseBlueprintQueryParams } from './types';

export const blueprintsQueryKeys: unknown[] = ['blueprints', 'query'];

export const useBlueprintsQuery = (
  { appID, iterator, sortBy, filterBy }: IUseBlueprintQueryParams,
  options?: UseQueryOptions<QueryBlueprintEntitiesResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => blueprintsQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, iterator, sortBy, filterBy]
  );

  const { data, errorMessage, isLoading, isFetching, mutate, refetch } = useEitherQuery(
    keys,
    () => services2.blueprintsService.queryBlueprints(appID as AppID, iterator, sortBy, filterBy),
    { keepPreviousData: true, ...options, enabled: options?.enabled !== false && appID !== undefined }
  );

  const mutateUpdatedPromotions = (
    promotions: Updater<IEntityPromotion<BlueprintEntityID>[], IEntityPromotion<BlueprintEntityID>[]>
  ) => {
    mutate((prevResponse: QueryBlueprintEntitiesResponse | undefined) => {
      if (!prevResponse) {
        return;
      }

      const response = new QueryBlueprintEntitiesResponse(prevResponse.serialize());
      const nextPromtions: IEntityPromotion<BlueprintEntityID>[] =
        typeof promotions === 'function' ? promotions(response.promotions) : promotions;

      response.promotions = nextPromtions.map(
        (p: IEntityPromotion<BlueprintEntityID>) =>
          new BlueprintEntityPromotions({
            id: p.id.serialize(),
            promotions: p.promotions.serialize(),
          })
      );

      return response;
    });
  };

  return {
    data,
    errorMessage,
    isLoading,
    isFetching,
    tags: keys,
    refetch,
    mutateUpdatedPromotions,
  };
};
