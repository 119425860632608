import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { FileID, FileResponse, FileFeature, File } from '@playq/octopus2-files';
import { Tag } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { fileGetKeys } from './constants';

export const useFileGet = (
  id?: FileID,
  features: FileFeature[] = [],
  options?: UseQueryOptions<FileResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => fileGetKeys.concat(id), [id]);

  const { mutate, ...res } = useEitherQuery(keys, () => services2.filesService.getFile(id as FileID, features), {
    ...options,
    enabled: (options?.enabled ?? true) && !!id,
  });

  const mutateUpdatedFile = useCallback(
    (file: File, tags?: Tag[]) => {
      if (res.data) {
        const updated = new FileResponse(res.data.serialize());
        updated.file = file;
        if (tags) {
          updated.tags = tags;
        }
        mutate(updated);
      }
    },
    [mutate, res.data]
  );

  return {
    fileResponse: res.data,
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedFile,
  };
};
